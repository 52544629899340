import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './routing/Routing';

import './assets/styles/antLayout.scss';

function App() {
  return (
    <div className="App">
        <BrowserRouter>
          <AppRoutes/>
        </BrowserRouter>
    </div>
  );
}

export default App;
