import React from 'react'
import HeaderContent from '../components/layout/Header'
import FooterContent from '../components/layout/Footer'
import HaztePartner from '../components/hazte-partner/HaztePartner'

const HaztePartnerPage = () => {
  return (
    <div>
        <HeaderContent/>
        <HaztePartner/>
        <FooterContent classname="footer-hazte-partner"/>
    </div>
  )
}

export default HaztePartnerPage