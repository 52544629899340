import { Col, Row, Typography } from 'antd';
import React from 'react';

const { Title, Text } = Typography;

const ContentContacto = () => {
  return (
    <div className='content-contacto'>
      <div className='contacto-img'>
        <Row gutter={0}>
          <Col xs={24} sm={24} md={24}>
            <div className='details-mobile'>
              <Title level={1} className='title'>
                Contáctanos
              </Title>
                <Col xs={24} sm={24} md={24} style={{ textAlign: 'center' }}>
                <Text className='subtitle'>
                  ¿Tienes dudas o quieres ser parte de Innovation Lab?
                </Text>
                </Col>
                <Col xs={24} sm={24} md={24} style={{ textAlign: 'center' }}>
                <Text className='subtitle'>
                  Escríbenos y te contactaremos a la brevedad para resolver todas tus inquietudes.
                </Text>
                </Col>
                <Col xs={24} sm={24} md={24} style={{ textAlign: 'center' }}>
                <Text className='subtitle'>
                  De igual forma, en esta sección te entregamos una selección de las preguntas que recibimos con mayor frecuencia y sus respuestas.
                </Text>
              </Col>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ContentContacto;