import { Route, Routes } from 'react-router-dom'
import PagePrincipal from '../pages/Index'
import ContactoPage from '../pages/ContactoPage'
import CatalogoPage from '../pages/CatalogoPage'
import HaztePartnerPage from '../pages/HaztePartnerPage'
import DetalleProductoPage from '../pages/DetalleProductoPage'

const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/home" element={<PagePrincipal/>}/>
            <Route path="/catalogo-datos" element={<CatalogoPage/>}/>
            <Route path="/detalle-producto/:id" element={<DetalleProductoPage />} />
            <Route path="/hazte-partner" element={<HaztePartnerPage/>}/>
            <Route path="/contacto" element={<ContactoPage/>}/>
            <Route path='/' element={<PagePrincipal/>}/>
        </Routes>
    )
}

export default AppRoutes