import { Card, Col, Row, Space, Table, Tabs, Tag } from 'antd';
import React, { useState, useEffect }  from 'react'
import { getCatalogoDatosBySet } from '../../services/Catalogo';
import { useParams } from "react-router-dom";
import { setDataCatalogoById } from '../../redux/reducers/AppReducer';
import { useDispatch } from 'react-redux';



const TabEsquema = () => {

  const [datosCatalogo, setDatoCatalogo,] = useState([]);
  const dispatch = useDispatch();
  const params = useParams( )
  useEffect(() => {
    fetchData()
   }, [])
 
const fetchData = async () => {
    Promise.all([getCatalogoDatosBySet(params.id)]).then((values) => {
        setDatoCatalogo(values[0]?.data?.catalogoDatos);
        dispatch(setDataCatalogoById([values[0]?.data]));
    });
};


    const columns = [
        {
            title: 'Nombre columna',
            dataIndex: 'name',
            key: 'name',
            width: 90,
        },
        {
          title: 'Tipo de dato',
          dataIndex: 'type',
          key: 'type',
          width: 80,
        },
        {
            title: 'Descripción columna',
            dataIndex: 'desc',
            key: 'desc',
            width: 150,
        },
      ];

      
     
  return (
    <div style={{ marginTop: 25 }}>
        <Tabs
            className='card-detalle'
            type="card"
            items={new Array(1).fill(null).map((_, i) => {
            const id = String(i + 1);
            return {
                label: <div className='divDescripcion'>Esquema</div>,
                key: id,
                children: 
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={24}>
                        <Card style={{ marginTop: -17, backgroundColor: 'rgba(11, 35, 35, 0.247) !important' }}>
                            <div style={{ overflowX: 'auto' }}>
                                <Table columns={ columns } dataSource={datosCatalogo.length > 0 && datosCatalogo.length > 0 && datosCatalogo.map(({IdDatoCatalogo, ColumnName, DataType, ColumnDescription}) => ({key: IdDatoCatalogo, name: ColumnName, type: DataType, desc: ColumnDescription})) } 
                                pagination={false}
                                scroll={{ y: 'min-content' }}
                                /> 
                            </div>
                        </Card>
                    </Col>
                </Row>,
            };
            })}
        />
    </div>
  )
}

export default TabEsquema