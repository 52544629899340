import { Button, Card, Col, Flex, Form, Input, Row, Select, Typography, Checkbox } from 'antd';
import { DownOutlined, SendOutlined, UpOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import React, { useEffect, useState } from 'react'
import { enviarFormulario, getTipoCliente, getTipoContacto } from '../../services/Contacto';
import ModalMensaje from '../modal/ModalMensaje';
import { daFormatoRut, esRutValido, formatRut, validateRut } from '../../utils/Util';
import { MotivoContactoInnovationEnum, TipoDocumentoId } from '../../enum/enum';
import ModalTerminosCondiciones from '../modal/ModalTerminosCondiciones';
import { Link, useLocation } from 'react-router-dom';
import { getDocumento } from '../../services/Catalogo';
import { saveAs } from 'file-saver'
import FormularioContactoWeb from './FormularioContactoWeb';
import FormularioContactoMobile from './FormularioContactoMobile';
import { useMediaQuery } from 'react-responsive';



const { Text } = Typography;




const FormularioContacto = () => {
  const idProductoInnovationLab = 30
 
  const isMobile = useMediaQuery({ maxWidth: 767 });


  const [expanded, setExpanded] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
  });


 
  const toggleExpanded = (index) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [index]: !prevExpanded[index],
    }));
  };


  

  const onDownloadDocumento = async (idProducto, idTipoDocumento) => {
    try {
      //setLoading(true)
      const docRes = await getDocumento(idProducto, idTipoDocumento)
      if(docRes) {
        const blobFile = new Blob(
          [docRes.buffer],
          { type: docRes.contentType }
        )
        saveAs(blobFile, docRes.fileName)
      }else {
        console.log('Sin respuesta');
      }
    } catch (error) {
      console.error('Error al descargar el documento');
      //setLoading(false)
    } finally {
      //setLoading(false)
    }
  }

  return (
    <div className='content-form'>
      <Row gutter={[16, 16]}>
        <Col xs={2} sm={2} md={1}></Col>
        <Col xs={0} sm={0} md={10}>
          <div style={{ paddingTop: 60 }}>
            <Card className='card-formulario'
              style={{ backgroundColor: '#00AFB30A' }}
            >
              <Text style={{ color: '#fff', fontSize: 20, fontFamily: 'Roboto' }}>¡Hablemos!</Text>
              <FormularioContactoWeb/>
            </Card>
          </div>
        </Col>
        <Col xs={24} sm={24} md={0}>
          <div style={{ paddingTop: 60 }}>
            <Card className='card-formulario'
              style={{ backgroundColor: '#00AFB30A' }}
            >
              <Text style={{ color: '#fff', fontSize: 20, fontFamily: 'Roboto' }}>¡Hablemos!</Text>
              <FormularioContactoMobile/>
            </Card>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <div style={{ marginTop: 60, marginLeft: 20 }}>
            <Text style={{ color: '#fff', fontWeight: 700, fontSize: 20, fontFamily: 'Roboto' }}>Preguntas frecuentes</Text>
          </div>
          <div className='card-faq-mobile' style={{ marginLeft: 20, marginTop: 20 }}>
            <Card
              onClick={() => toggleExpanded(1)}
              className='card-faq'
              style={{
                borderColor: '#0E8DA6',
                padding: '4px 10px 10px 10px',
                height: expanded[1] ? 'auto' : 50,
                backgroundColor: '#r02111a !important',
                cursor: 'pointer',
              }}
            >
              <Flex justify='space-between' align='flex-start' style={{ pointerEvents: 'none' }}>
                <Text style={{ color: '#fff', fontWeight: 'bold' }}> ¿Qué es Innovation Lab Brain Data?</Text>
                {expanded[1] ? (
                  <UpOutlined style={{ color: '#FFC000', marginTop: 2 }} />
                ) : (
                  <DownOutlined style={{ color: '#FFC000', marginTop: 2 }} />
                )}
              </Flex>
              <p style={{ color: '#fff', pointerEvents: 'none' }}>
                {expanded[1] && (
                  <>
                    <span>
                      {`Brain Data es el ecosistema de productos y servicios basados en una arquitectura Cloud de Big Data & Analytics implementada en la Bolsa de Santiago.`}
                    </span>
                    <span style={{ display: 'block', marginTop: 10 }}>
                      {`Innovation Lab es parte de este ecosistema, donde se ofrecen ambientes de trabajo o Workspaces para aquellos que tengan la necesidad y deseen testear y experimentar con datos transaccionales de la Bolsa de Santiago, para así explorar y co-crear en conjunto nuevas oportunidades de negocio que pueden ser de valor para el mercado financiero y bursátil.`.replace(/\?/g, '?<br/><br/>')}
                    </span>
                    <span style={{ display: 'block', marginTop: 10 }}>
                      {`Con Innovation Lab tendrás acceso a conjuntos de datos transaccionales reales de la Bolsa, poderosas herramientas de análisis y oportunidades comerciales, todo en un ecosistema seguro, abierto e innovador.`.replace(/\?/g, '?<br/><br/>')}
                    </span>
                  </>
                )}
              </p>
            </Card>
          </div>

          <div className='card-faq-mobile' style={{ marginLeft: 20, marginTop: 20 }}>
            <Card
              className='card-faq'
              onClick={() => toggleExpanded(2)}
              style={{
                borderColor: '#0E8DA6',
                padding: '4px 10px 10px 10px',
                height: expanded[2] ? 'auto' : 50,
                backgroundColor: expanded[2] ? 'rgba(14, 141, 166, 0.25)' : '#r02111a',
                cursor: 'pointer',
              }}
            >
              <Flex justify='space-between' align='flex-start' style={{ pointerEvents: 'none' }}>
                <Text onClick={() => toggleExpanded(2)} style={{ color: '#fff', fontWeight: 'bold' }}> ¿Para quién está pensada esta iniciativa?</Text>
                {expanded[2] ? (
                  <UpOutlined onClick={() => toggleExpanded(2)} style={{ color: '#FFC000', marginTop: 2 }} />
                ) : (
                  <DownOutlined onClick={() => toggleExpanded(2)} style={{ color: '#FFC000', marginTop: 2 }} />
                )}
              </Flex>
              <p style={{ color: '#fff' }}>
              {expanded[2] && (
                  'Innovation Lab está dirigido a investigadores, académicos, universidades o fintechs que deseen testear con datos de la Bolsa de Santiago y co-crear oportunidades en conjunto.'
                )}
              </p>
            </Card>
          </div>

          <div className='card-faq-mobile' style={{ marginLeft: 20, marginTop: 20 }}>
            <Card
              onClick={() => toggleExpanded(3)}
              className='card-faq'
              style={{
                borderColor: '#0E8DA6',
                padding: '4px 10px 10px 10px',
                height: expanded[3] ? 'auto' : isMobile ? 70 : 50,
                backgroundColor: expanded[3] ? 'rgba(14, 141, 166, 0.25)' : '#r02111a',
                cursor: 'pointer',
              }}
            >
              <Flex justify='space-between' align='flex-start' style={{ pointerEvents: 'none' }}>
                <Text onClick={() => toggleExpanded(3)} style={{ color: '#fff', fontWeight: 'bold' }}> ¿Qué Workspaces hay disponibles para su uso?</Text>
                {expanded[3] ? (
                  <UpOutlined onClick={() => toggleExpanded(3)} style={{ color: '#FFC000', marginTop: 2 }} />
                ) : (
                  <DownOutlined onClick={() => toggleExpanded(3)} style={{ color: '#FFC000', marginTop: 2 }} />
                )}
              </Flex>
              <p style={{ color: '#fff' }}>
              {expanded[3] && (
                <>
                <span>
                {`Tenemos disponible 5 tipos de ambientes de trabajo Apache Spark, los cuales proporcionan una API de alto nivel en Java, Scala, Python, R y un motor optimizado que admite gráficos de ejecución general.`} 
                
                </span><span style={{ display: 'block', marginTop: 10 }}>
                  {`Estos Workspaces se diferencian principalmente por la capacidad de procesamiento que permite cada uno, puedes utilizarlos para pruebas de concepto pequeñas o para cargas de trabajo intensivas, todo depende de tu necesidad.`} 
                  </span><span style={{ display: 'block', marginTop: 10 }}>
                  {`Para más información revisa el siguiente`} <Text type='link' style={{ color: '#06EFFE', textDecoration: 'underline', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => onDownloadDocumento(idProductoInnovationLab, TipoDocumentoId.FOLLETO_PRODUCTO)}>Folleto</Text> {` del producto.`}
                  </span>
                  </>
                )}
              </p>
            </Card>
          </div>

          <div className='card-faq-mobile' style={{ marginLeft: 20, marginTop: 20 }}>
            <Card
              onClick={() => toggleExpanded(4)}
              className='card-faq'
              style={{
                borderColor: '#0E8DA6',
                padding: '4px 10px 10px 10px',
                height: expanded[4] ? 'auto' : isMobile ? 70 : 50,
                backgroundColor: expanded[4] ? 'rgba(14, 141, 166, 0.25)' : '#r02111a',
                cursor: 'pointer',
              }}
            >
              <Flex justify='space-between' align='flex-start' style={{ pointerEvents: 'none' }}>
                <Text onClick={() => toggleExpanded(4)} style={{ color: '#fff', fontWeight: 'bold' }}> ¿Qué tipo de datos se ofrecen en los Workspaces y de dónde provienen?</Text>
                {expanded[4] ? (
                  <UpOutlined onClick={() => toggleExpanded(4)} style={{ color: '#FFC000', marginTop: 2 }} />
                ) : (
                  <DownOutlined onClick={() => toggleExpanded(4)} style={{ color: '#FFC000', marginTop: 2 }} />
                )}
              </Flex>
              <p style={{ color: '#fff' }}>
              {expanded[4] && (
                  <>
                  <span>
              {`Los datos que se encuentran disponibles en Innovation Lab son principalmente transaccionales, los cuales pertenecen a los mercados de Renta Variable, Renta Fija, 
                  Intermediación Financiera y Compensación y Liquidación de la Bolsa de Santiago. En la sección Catálogo de datos de este sitio puedes revisar con mayor detalle estos datos.`}
                  </span><span style={{ display: 'block', marginTop: 10 }}>
                  {`La información contenida en los ambientes de trabajo proviene directamente del repositorio central de datos de la Bolsa, el cual se encuentra en una infraestructura Cloud.`}
                  </span>
                  </>
                )}
              </p>
            </Card>
          </div>

          <div className='card-faq-mobile' style={{ marginLeft: 20, marginTop: 20 }}>
            <Card
              onClick={() => toggleExpanded(5)}
              className='card-faq'
              style={{
                borderColor: '#0E8DA6',
                padding: '4px 10px 10px 10px',
                height: expanded[5] ? 'auto' : 50,
                backgroundColor: expanded[5] ? 'rgba(14, 141, 166, 0.25)' : '#r02111a',
                cursor: 'pointer',
              }}
            >
              <Flex justify='space-between' align='flex-start' style={{ pointerEvents: 'none' }}>
                <Text onClick={() => toggleExpanded(5)} style={{ color: '#fff', fontWeight: 'bold' }}>¿Qué puedo hacer con Innovation Lab?</Text>
                {expanded[5] ? (
                  <UpOutlined onClick={() => toggleExpanded(5)} style={{ color: '#FFC000', marginTop: 2 }} />
                ) : (
                  <DownOutlined onClick={() => toggleExpanded(5)} style={{ color: '#FFC000', marginTop: 2 }} />
                )}
              </Flex>
              <p style={{ color: '#fff' }}>
              {expanded[5] && (
                <>
                <span>
                {`Innovation Lab te permite realizar actividades desde la exploración de datos hasta la distribución de tus descubrimientos por medio del desarrollo de productos financieros comercializables al mercado.`}
                </span><span style={{ display: 'block', marginTop: 10 }}>
                {`En los Workspaces podrás revisar, testear y desarrollar modelos a partir de información transaccional de la Bolsa, para luego esto transformarlo en interesantes servicios como Dashboards, APIs, set de datos, entre una variedad de opciones más.`}
                </span>
                </>
                )}
              </p>
            </Card>
          </div>

          <div className='card-faq-mobile' style={{ marginLeft: 20, marginTop: 20 }}>
            <Card
              onClick={() => toggleExpanded(6)}
              className='card-faq'
              style={{
                borderColor: '#0E8DA6',
                padding: '4px 10px 10px 10px',
                height: expanded[6] ? 'auto' : isMobile ? 70 : 50,
                backgroundColor: expanded[6] ? 'rgba(14, 141, 166, 0.25)' : '#r02111a',
                cursor: 'pointer',
              }}
            >
              <Flex justify='space-between' align='flex-start' style={{ pointerEvents: 'none' }}>
                <Text onClick={() => toggleExpanded(6)} style={{ color: '#fff', fontWeight: 'bold' }}> ¿Cómo puedo acceder a un Workspace de Innovation Lab?</Text>
                {expanded[6] ? (
                  <UpOutlined onClick={() => toggleExpanded(6)} style={{ color: '#FFC000', marginTop: 2 }} />
                ) : (
                  <DownOutlined onClick={() => toggleExpanded(6)} style={{ color: '#FFC000', marginTop: 2 }} />
                )}
              </Flex>
              <p style={{ color: '#fff' }}>
              {expanded[6] && (
                'Contáctate con nuestro equipo de Brain Data a través del formulario de esta sección para revisar tu caso y, de ser aplicable, entregarte acceso a un Workspace para comenzar a co-crear.'
                )}
              </p>
            </Card>
          </div>
        </Col>
        <Col xs={2} sm={2} md={2}></Col>
      </Row>
      
    </div>
  )
}

export default FormularioContacto