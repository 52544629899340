import { Button, Card, Carousel, Col, Row, Typography } from 'antd';
import React, { useLayoutEffect, useRef, useState } from 'react'
import Controles from '../controles/Controles';
import { TipoDocumentoId } from '../../enum/enum';
import { getDocumento } from '../../services/Catalogo';
import { saveAs } from 'file-saver'
import { Link } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';



import robot from '../../assets/images/cash-macanaya-X9Cemmq4YjM-unsplash 1 1.png'
import datos from '../../assets/images/Tarjeta 1 Conjunto de datos.png';
import workspace from '../../assets/images/Tarjeta 2 Workspaces.png';
import consultas from '../../assets/images/Tarjeta 4 Consultas.png';


const { Title, Text } = Typography;

const SliderBeneficiosWeb = () => {
    const idProductoInnovationLab = 30
    const [currentCard, setCurrentCard] = useState(0);
    const carouselRef = React.createRef();
    const [cardMaxHeight, setMaxCardHeight] = useState(0)
    const [heightsCards, setHeightsCards] = useState([])
    const [loading, setLoading] = useState(false)
  
    const goToSlide = (slideToGo) => {
      setCurrentCard(slideToGo)
      carouselRef.current.goTo(slideToGo)
  
      const height = heightsCards.filter(x => x.index === slideToGo)[0].heightContainer + 'px'
      const root = document.querySelector("#carrousel-como-funciona")
      root?.style.setProperty('max-height', height)
    };
  
    const handleNext = () => {
      const nextIndex = (currentCard + 1) % cardsCarrousel.length;
      goToSlide(nextIndex);
    };
  
    const handlePrev = () => {
      const prevIndex = currentCard === 0 ? cardsCarrousel.length - 1 : currentCard - 1;
      goToSlide(prevIndex);
    };

    const onDownloadDocumento = async (idProducto, idTipoDocumento) => {
      try {
        setLoading(true)
        const docRes = await getDocumento(idProducto, idTipoDocumento)
        if(docRes) {
          const blobFile = new Blob(
            [docRes.buffer],
            { type: docRes.contentType }
          )
          saveAs(blobFile, docRes.fileName)
        }else {
          console.log('Sin respuesta');
        }
      } catch (error) {
        console.error('Error al descargar el documento');
        setLoading(false)
      } finally {
        setLoading(false)
      }
    }
  
    useLayoutEffect(() => {
  
      // ref: https://github.com/kenwheeler/slick/issues/1803
      if (!cardMaxHeight || cardMaxHeight === 0) {
        const arr = []
        let maxHeightAux = null;
        for (let index = 0; index < cardsCarrousel.length; index++) {
          const card = cardsCarrousel[index];
          // console.log("card.elementRef", card.elementRef)
          if (card.elementRef.current) {
            arr.push({
              width: card.elementRef.current.offsetWidth,
              height: card.elementRef.current.offsetHeight,
              heightContainer: card.elementRef.current.offsetHeight + 430, //ver si puedo obtener el alto pantall y le sumo
              index: index
            })
            if (card.elementRef.current.offsetHeight > maxHeightAux) {
              maxHeightAux = card.elementRef.current.offsetHeight
            }
          }
        }
        setHeightsCards(arr)
        setMaxCardHeight(maxHeightAux + 5)//agrego 5 ya que si no, no se ve borde de card de documentacion
  
        const height = arr[0].heightContainer + 'px'
        const root = document.querySelector("#carrousel-como-funciona")
        root?.style.setProperty('max-height', height)
      }
    }, []);

    const onRedirect = () => {
      window.scrollTo(0, 0)
    }

    const cardsCarrousel = [
        {
          elementRef: useRef(),
          id: 0,
          element: (paramRef, paramStyle) =>
          <Card ref={paramRef} className='card-carrousel' style={paramStyle}>
            <div className="half">
              <div className="left-half">
                <img src={datos} alt="Imagen" style={{ width: 563, height: 432 }} />
              </div>
              <div className="right-half">
                <div className='detalle-card'>
                  <Title level={5} className='titulo-card' style={{ color: '#fff', fontSize: 30, marginTop: 5 }}>
                    Conjuntos de datos
                  </Title>
                  <Text style={{ color: '#fff', fontSize: 15 }}>
                  Explora nuestro <Link to="/catalogo-datos" onClick={onRedirect} style={{ color: '#06EFFE', fontWeight: 'bold', textDecoration: 'underline'}}>Catálogo de datos</Link> para ver los sets de información de la Bolsa de Santiago que están disponibles para su uso y análisis en los Workspaces.
                  </Text>
                  
                  <Controles handleNext={handleNext} handlePrev={handlePrev}/>
                </div>
              </div>
            </div>
          </Card>
        },
        {
          id: 1,
          element: (paramRef, paramStyle) =>
          <Card ref={paramRef} className='card-carrousel' style={paramStyle}>
            <div className="half">
              <div className="left-half">
                <img src={workspace} alt="Imagen" style={{ width: 563, height: 432 }}/>
              </div>
              <div className="right-half">
                <div className='detalle-card'>
                  <Title level={5} className='titulo-card' style={{ color: '#fff', fontSize: 30, marginTop: 5 }}>
                    Workspaces
                  </Title>
                  <Text style={{ color: '#fff', fontSize: 15 }}>
                  Revisa los ambientes y planes disponibles en el siguiente Folleto, y la Guía de uso para entender cómo funciona este servicio. Solicita la habilitación de un Workspace a través del formulario de <Link to="/contacto" onClick={onRedirect} style={{ color: '#06EFFE', fontWeight: 'bold', textDecoration: 'underline'}}>Contacto.</Link>
                  </Text>
                  <Row gutter={[16, 16]} style={{ marginTop: 20 }}>
                    <Col xs={24}>
                        <Button type='primary' shape='round' className='btnsolicita' onClick={() => onDownloadDocumento(idProductoInnovationLab, TipoDocumentoId.FOLLETO_PRODUCTO)}  style={{ marginRight: 16, backgroundColor: '#D9D9D9', color: 'black', fontWeight: 'bold', fontFamily: 'Roboto', fontSize: 13, width: '30%' }}>Folleto {loading ? (<LoadingOutlined/>) : null}</Button>
                        <Button type='primary' shape='round' onClick={() => onDownloadDocumento(idProductoInnovationLab, TipoDocumentoId.DOCUMENTACION)} style={{ backgroundColor: '#000', color: '#fff', fontWeight: 'bold', fontFamily: 'Roboto', fontSize: 13, borderColor: 'white', width: '30%' }}>Guía de uso {loading ? (<LoadingOutlined/>) : null}</Button>
                    </Col>
                  </Row>
                  <Controles handleNext={handleNext} handlePrev={handlePrev}/>
                </div>
              </div>
            </div>
          </Card>,
          elementRef: useRef()
        },
        {
          id: 2,
          element: (paramRef, paramStyle) =>
          <Card ref={paramRef} className='card-carrousel' style={paramStyle}>
            <div className="half">
              <div className="left-half">
                <img src={robot} alt="Imagen" style={{ width: 563, height: 432 }} />
              </div>
              <div className="right-half">
                <div className='detalle-card'>
                  <Title level={5} className='titulo-card' style={{ color: '#fff', fontSize: 30, marginTop: 5 }}>
                    Partnership
                  </Title>
                  <Text style={{ color: '#fff', fontSize: 15 }}>
                    Si ya tienes una idea o proyecto en mente, pero no sabes por donde comenzar, conversémos para explorar oportunidades de financiamiento a través de un partnership. Y así co-crear en conjunto soluciones que entreguen valor al mercado y generen posibles ingresos.
                  </Text>
                  <br/>
                  <Text style={{ color: '#fff', fontSize: 15 }}>Para más información dirígite a la sección <Link to="/hazte-partner" onClick={onRedirect} style={{ color: '#06EFFE', fontWeight: 'bold', textDecoration: 'underline' }}>Hazte Partner</Link> de este sitio.</Text>
                  <Controles handleNext={handleNext} handlePrev={handlePrev}/>
                </div>
              </div>
            </div>
          </Card>,
          elementRef: useRef()
        },
        {
          id: 3,
          element: (paramRef, paramStyle) =>
          <Card ref={paramRef} className='card-carrousel' style={paramStyle}>
            <div className="half">
              <div className="left-half">
                <img src={consultas} alt="Imagen" style={{ width: 563, height: 432 }} />
              </div>
              <div className="right-half">
                <div className='detalle-card' style={{  }}>
                  <Title level={5} className='titulo-card' style={{ color: '#fff', fontSize: 30, marginTop: 5 }}>
                    Consultas
                  </Title>
                  <Text style={{ color: '#fff', fontSize: 15 }}>
                    Si tienes consultas, necesitas mayor información del producto o deseas una demo, contáctate con nuestro equipo a través del formulario de la sección <Link to="/contacto" onClick={onRedirect} style={{ color: '#06EFFE', fontWeight: 'bold', textDecoration: 'underline'}}>Contacto</Link> de este sitio.
                  </Text>
                  
                  <Controles handleNext={handleNext} handlePrev={handlePrev}/>
                </div>
              </div>
            </div>
          </Card>,
          elementRef: useRef()
        },
        
      ]
  return (
        <Row gutter={[16, 16]} style={{ marginTop: 40, marginLeft: 10 }}>
          <Col xs={0} sm={0} md={24} lg={23} xl={23} xxl={23}>

              <div className='customCarousel como-funciona carousel-container'
              >
              <Carousel
                  ref={carouselRef}
                  id="carrousel-como-funciona"
              >
                  {cardsCarrousel.map((card, index) => {
                  let cardStyle = {}
                  //establecemos un margen en todas las tarjetas cuyo with no sea igual al máximo
                  if (card.elementRef?.current?.offsetHeight < cardMaxHeight) {
                      const marginBotomAux = Math.ceil(
                      (cardMaxHeight - card.elementRef.current.offsetHeight)) - 1

                      const marginBotomAuxPx = `${marginBotomAux}px`
                      cardStyle = { marginBottom: marginBotomAuxPx }
                  }

                  return (<React.Fragment key={index} children={card.element(card.elementRef, cardStyle)} />)
                  })}
              </Carousel>

              </div>
          </Col>

          <Col xs={0} sm={0} md={0} lg={1} xl={1} xxl={1}>
              <div className={`custom-dots-container ${currentCard === 7 ? 'dot-documentacion' : ''}`}>
              {Array(cardsCarrousel.length).fill().map((_, index) => (
                  <span
                  key={index}
                  className={`custom-dot ${currentCard === index ? 'active' : ''}`}
                  onClick={() => goToSlide(index)}
                  />
              ))}
              </div>
          </Col>
        </Row >
  )
}

export default SliderBeneficiosWeb