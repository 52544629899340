import { PublicAxios } from './Helpers/publicInterceptorAxios';

const urlApi = process.env.REACT_APP_BACKEND_URL;

export const getAllSetDatos = async () => {
    return PublicAxios.get(`${urlApi}/api/CatalogoDatos`)
}
export const getCatalogoDatosBySet = async (id) => {
    return PublicAxios.get(`${urlApi}/api/CatalogoDatos/CatalogoDatosBySet/${id}`)
}

export const getCatalogosDatosById = async (id) => {
    return PublicAxios.get(`${urlApi}/api/CatalogoDatos/CatalogoDatosById/${id}`)
}

/**
 * Obtiene data a partir de un filtro de tipo categoria 'Renta Fija, Renta Variasble, Intermediacion Financiera, Compensación y Liquidación'
 * @param {Array} idCategories - id del tipo de categoria
 * @returns { Promise<any> } 
 **/
export const getCatalogoDatosByCategories = async (idCategories) => {
    return PublicAxios.get(`${urlApi}/api/CatalogoDatos/CatalogoDatosByCategory?IdsCategoriaFiltro=${idCategories}`)
}

/**
 * Obtiene un documento de un producto según su tipo
 * @param {number} idProducto -identificador del producto
 * @param {number} idTipoDocumento - identificador del tipo de documento del producto. 1-> Contrato de producto; 2->Folleto producto; 3-> Documentacion
 * @returns {Promise<any>} Archivo en formato "arrayBuffer"
 */

export const getDocumento = async (idProducto, idTipoDocumento) => {
    try {

        return PublicAxios
        .get(
                `${urlApi}/api/CatalogoDatos/producto/documento/${idProducto}/${idTipoDocumento}`,
                { responseType: 'arraybuffer' }
            )
            .then((res) => {
                const contentDisposition = res.headers["content-disposition"]
                let startFileNameIndex = contentDisposition.indexOf('"') + 1
                let endFileNameIndex = contentDisposition.lastIndexOf('"');
                let filename = contentDisposition.substring(startFileNameIndex, endFileNameIndex);

                return {
                    buffer: res.data,
                    contentType: res.headers["content-type"],
                    fileName: filename
                }
            })
    }
    catch (error) {
        return Promise.reject(error)
    }
}