import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { Avatar, Button, Col, Modal, Row } from 'antd'
import React from 'react'

/**
 * 
 * @param {{
 * isModalOpen:boolean,
 * onClose:Function,
 * message:string,
 * success:boolean
 * }} props del componente
 * @param props.isModalOpen- Indica si el modal debe desplegarse
 * @returns {React.ComponentElement}
 */
const ModalMensaje = ({ isModalOpen, onClose, message, success }) => {
    return (
        <Modal
            closable={false}
            centered
            open={isModalOpen}
            onOk={onClose}
            width={450}
            footer={[]
            }            >
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                {success ?
                    <Avatar style={{ backgroundColor: '#1677ff' }} size={80} icon={<CheckCircleOutlined />} />
                    :
                    <Avatar style={{ backgroundColor: '#1677ff' }} size={80} icon={<CloseCircleOutlined />} />
                }

                <Row>
                    <Col span={24}>
                        <p>{message}</p>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Button key="submit" type="primary" shape="round" onClick={onClose}>
                            Aceptar
                        </Button>
                    </Col>
                </Row>
            </div>
        </Modal>
    )
}

export default ModalMensaje