import { createSlice } from "@reduxjs/toolkit";


const appSlice = createSlice({
    name: 'app',
    initialState: {
        dataCatalogo: [],
        dataCatalogoFiltrado: [],
        dataCatalogoById: [],
    },
    reducers: {
        setDataCatalogoFiltrado(state, action) {
            state.dataCatalogoFiltrado = action.payload;
        },
        setDataCatalogo(state, action){
            state.dataCatalogo = action.payload;
        },
        setDataCatalogoById(state, action) {
            state.dataCatalogoById = action.payload;
        }
    }
})

export const { setDataCatalogo, setDataCatalogoFiltrado, setDataCatalogoById } = appSlice.actions

export default appSlice.reducer