import React from 'react'
import HeaderContent from '../components/layout/Header'
import FooterContent from '../components/layout/Footer'
import Detalle from '../components/detalle-producto/Detalle'

const DetalleProductoPage = () => {
  return (
    <>
        <HeaderContent/>
        <Detalle/>
        <FooterContent/>
    </>
  )
}

export default DetalleProductoPage