export const MotivoContactoInnovationEnum = {
    CONSULTA: 1,
    QUIERO_CONTRATAR_PLAN: 2,
    QUIERO_SER_PARTNER: 3,
    NECESITO_AYUDA: 4,
    OTRO_MOTIVO: 5,
    //Este estado no se carga en landing, solo en sitio privado
    //MODIFICAR_PLAN_SUSCRITO
}

export const TipoDocumentoId = {
	CONTRATO_PRODUCTO: 1,
	FOLLETO_PRODUCTO: 2,
	DOCUMENTACION: 3,
}