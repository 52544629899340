import { Button, Col, Divider, Layout, Row, Typography, Flex } from 'antd'
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import ModalTerminosCondiciones from '../modal/ModalTerminosCondiciones';

import Face from '../../assets/images/facebook.svg';
import insta from '../../assets/images/instagram.svg';
import twitter from '../../assets/images/linkedin.svg';
import logoBolsa from '../../assets/images/logo-bolsa-w.svg';



const { Footer } = Layout;
const { Text } = Typography;

const FooterContent = () => {

  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleExternalLink = (url) => {
    window.open(url, "_blank");
  }

  const onRedirect = () => {
    window.scrollTo(0, 0)
  }

  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleOk = () => {
      setIsModalOpen(false);
  };

  const handleCancel = () => {
      setIsModalOpen(false);
  };

  return (
    <>
      <Footer className='footer'>
        <div className='content-footer'>
          <Row gutter={0} className='footer-margin'  >
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <img src={logoBolsa} alt='logo-bolsa' style={{ width: 200, paddingRight: 20 }}/>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Divider style={{ color: '#262B30'}} />
            </Col>
          </Row>
          
          <Row gutter={16} className='footer-mobile' >
              <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16} style={{ paddingRight: 20 }}>
                <Row gutter={0} >
                  <Col xs={24} sm={24} md={6} lg={4} xl={4} xxl={4}>
                    <Text strong style={{ marginBottom: '4px', color: '#fff' }}>
                      <Link to="/home" onClick={onRedirect} style={{ color: 'white' }}>Inicio</Link>
                    </Text>
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={4} xl={4} xxl={4}>
                  <Text strong className='link-catalogo' style={{ marginBottom: '4px', color: '#fff' }}>
                    <Link to="/catalogo-datos" onClick={onRedirect} style={{ color: 'white' }}>Catálogo de datos</Link>
                  </Text>
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={4} xl={4} xxl={4}>
                  <Text strong style={{ marginBottom: '4px', color: '#fff' }}>
                    <Link to="/hazte-partner" onClick={onRedirect} style={{ color: 'white' }}>Hazte partner</Link>
                  </Text>
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={4} xl={4} xxl={4}>
                  <Text strong className='link-contacto' style={{ marginBottom: '4px', color: '#fff' }}>
                    <Link to="/contacto" onClick={onRedirect} style={{ color: 'white' }}>Contacto</Link>
                  </Text>
                  </Col>
                </Row>
                </Col>
              <Col md={4} lg={4} xl={4} xxl={4}></Col>
              <Col xs={24} sm={24} md={8} lg={4} xl={4} xxl={4} className='redes-sociales'>
                <Text strong style={{ color: '#fff' }}>Síguenos en: </Text>
                <Row gutter={0} justify='flex-end'>
                  <Col style={{ marginTop: 4 }}>
                  <a
                      href='https://www.facebook.com/bolsadesantiago/'
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={(e) => {
                        e.preventDefault();
                        handleExternalLink(e.currentTarget.href);
                      }}
                    >
                    <img style={{ marginRight: '8px', verticalAlign: 'middle', width: 24  }} src={Face} alt='Facebook' />
                    </a>
                  </Col>
                  <Col style={{ marginTop: 4 }}>
                  <a
                      href='https://www.linkedin.com/company/35683183/'
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={(e) => {
                        e.preventDefault();
                        handleExternalLink(e.currentTarget.href);
                      }}
                    >
                    <img style={{ marginRight: '8px', verticalAlign: 'middle', width: 24 }} src={twitter} alt='Instagram' />
                    </a>
                  </Col>
                  <Col style={{ marginTop: 4 }}>
                      <a
                      href='https://www.instagram.com/labolsadesantiago/'
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={(e) => {
                        e.preventDefault();
                        handleExternalLink(e.currentTarget.href);
                      }}
                    >
                    <img style={{ marginRight: '8px', verticalAlign: 'middle', width: 24 }} src={insta} alt='Twitter' />
                    </a>
                  </Col>
                </Row>
              </Col>
          </Row>
          <Row gutter={0}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className='linea-horizontral'></Col>
          </Row>
          <Row gutter={0}>
            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24, offset: 0 }} style={{ marginTop: 30 }}>
              <div className="footer-content">
                <Text style={{ color: 'white' }}>©Copyrigth - 2024. Todos los derechos reservados. </Text>
                <Button className='footer-button' type='link' onClick={showModal} style={{  }}>
                  Ver Términos y Condiciones
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Footer>
      <ModalTerminosCondiciones isModalOpen={isModalOpen} handleOk={handleOk} handleCancel={handleCancel}/>
    </>
  )
}

export default FooterContent