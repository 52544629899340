import React from 'react'
import ContentHaztePartner from './ContentHaztePartner'
import CardsHaztePartner from './CardsHaztePartner'
import ContentFinalHaztePartner from './ContentFinalHaztePartner'

const HaztePartner = () => {
  return (
    <div className='hazte'>
        <div className='hazte-partner'>
          <ContentHaztePartner/>
          <CardsHaztePartner/>
          <ContentFinalHaztePartner/>
        </div>
    </div>
  )
}

export default HaztePartner