import { Button, Col, Row, Space, Typography } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom';

const { Title, Text } = Typography;

const ContentFinalHaztePartner = () => {
  const navigate = useNavigate();
  const onRedirect = (link) => {
    navigate(link)
    window.scrollTo(0, 0)
  }

  
  return (
    <div className='container-end'>
        <div className='final-max'>
        <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24}>
          <div className='margen-comienza' style={{  textAlign: 'left'  }}>
          <Title level={2} style={{ color: '#fff', fontFamily: 'Roboto' }}>Comienza ahora</Title>
          <Text style={{ color: '#D9D9D6', fontFamily: 'Roboto' }}>Explora nuestro Catálogo de datos, solicita un Workspace y conviértete en Partner de Innovation Lab para colaborar y transformar tus conocimientos en valor para la industria financiera.</Text>
          <div style={{  marginTop: 20 }}>
              <Space direction="horizontal" size="middle"  style={{ display: 'flex' }}>
                <Row gutter={[16, 16]}>
                  <Col xs={12} sm={12} md={24} lg={24} xl={24} xxl={24}>
                    <Button type='primary' className='btnsolicita' shape='round' style={{ backgroundColor: '#D9D9D9', color: 'black', fontWeight: 'bold', fontFamily: 'Roboto', fontSize: 13 }} onClick={() => onRedirect('/contacto')}>Solicita un Workspace</Button>
                    <Button shape='round' className='btnHaztePartnerPage' style={{ backgroundColor: '#000', color: '#fff', fontWeight: 'bold', fontFamily: 'Roboto', fontSize: 13}} onClick={() => onRedirect('/contacto')}>Contáctanos</Button>
                  </Col>
                </Row>
              </Space>
          </div>
          </div>
        </Col>
      </Row>
          
      </div>
    </div>
  )
}

export default ContentFinalHaztePartner