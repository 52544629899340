import { ClockCircleOutlined, FileSyncOutlined, TagOutlined } from '@ant-design/icons';
import { Card, Col, Row, Tabs, Typography } from 'antd';
import { useSelector } from 'react-redux';


const { Text } = Typography;
const TabDescripcion = () => {

    const dataCatalogoById = useSelector(state => state.aplicacion.dataCatalogoById);

  return (
    <div>
        <Tabs
            className='card-drescripcion'
            type="card"
            items={new Array(1).fill(null).map((_, i) => {
            const id = String(i + 1);
            return {
                label: <div className='divDescripcion'>Descripción</div>,
                key: id,
                children:
                <Card style={{ marginTop: -17, backgroundColor: 'rgba(11, 35, 35, 0.247)' }} >
                    <Row gutter={[16, 16]} justify={'space-around'}>
                        <Col xs={24} sm={24} md={9}>
                            <div style={{ display: 'flex' }}>
                                <TagOutlined style={{ fontSize: '35px', marginRight: '15px', color: '#06EFFE' }} />
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Text style={{ fontSize: '16px', marginBottom: '5px', color: '#fff', fontWeight: 'bold', lineHeight: 1.3 }}>Nombre set en Workspace</Text>
                                    <Text style={{ color: '#fff', lineHeight: '0' }}>{dataCatalogoById[0]?.setDatos?.NombreTabla} </Text>
                                </div>
                            </div>
                        </Col>                                                   
                        <Col xs={24} sm={24} md={11}>
                            <div className='espacio' style={{ display: 'flex' }}>
                                <ClockCircleOutlined style={{ fontSize: '35px', marginRight: '15px', color: '#06EFFE' }} />
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Text className='line' style={{ fontSize: '16px', marginBottom: -2, color: '#fff', fontWeight: 'bold' }}>Frecuencia de actualización de datos </Text>
                                    <Text style={{ color: '#fff', lineHeight: '1.8' }}>{dataCatalogoById[0]?.setDatos?.FrecuenciaActualizacion}</Text>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={4}>
                            <div className='espacio' style={{ display: 'flex' }}>
                                <FileSyncOutlined style={{ fontSize: '35px', marginRight: '15px', color: '#06EFFE' }} />
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Text style={{ fontSize: '16px', marginBottom: '3px', color: '#fff', fontWeight: 'bold' }}>Historicidad</Text>
                                    <Text style={{ color: '#fff', lineHeight: '0' }}>{dataCatalogoById[0]?.setDatos?.Historicidad}</Text>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Card>, 
            };
            })}
        />
    </div>
  )
}

export default TabDescripcion