import { CloseCircleFilled } from '@ant-design/icons';
import { Button, Col, Modal, Row } from 'antd'
import React, { useState } from 'react'
import { getAllSetDatos, getCatalogoDatosByCategories } from '../../services/Catalogo';
import { useDispatch } from 'react-redux';
import { setDataCatalogoFiltrado } from '../../redux/reducers/AppReducer';

import imgFiltro from '../../assets/images/filter.svg'

const ModalFiltro = ({ isModalOpen, handleOk, handleCancel, onUpdateClickedButtonsCount, resetClickedButtonsCount }) => {

  
  const dispatch = useDispatch();
  const [clickedButtons, setClickedButtons] = useState([]);
  const [idsCategories, setIdsCategories] = useState([]);
  const [cantidadResultados, setCantidadResultados] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [showingResult, setShowingResult] = useState(false);

  const handleButtonClick = (buttonText) => {
    setClickedButtons((prevClickedButtons) => {
      const updatedClickedButtons = prevClickedButtons.includes(buttonText)
        ? prevClickedButtons.filter((button) => button !== buttonText)
        : [...prevClickedButtons, buttonText];
      onUpdateClickedButtonsCount(updatedClickedButtons.length);
      setIdsCategories(updatedClickedButtons);

      return updatedClickedButtons;
    });
  };

  const buttonStyle = {
    marginRight: '10px',
    backgroundColor: 'rgba(3, 34, 42, 1)',
    boxShadow: '0 0 0',
    borderColor: 'rgba(24, 144, 255, 1)',
    color: 'rgba(24, 144, 255, 1)',
  };

  const resetButtons = () => {
    setClickedButtons([]);
    setIdsCategories([]);
    resetClickedButtonsCount();
    getAllSetDatos()
      .then((response) => {
        dispatch(setDataCatalogoFiltrado(response.data));
        setCantidadResultados(response.data.length);
      })
      .catch((error) => {
        console.error('Error al obtener los datos:', error);
      });
  };

  const handleResultados = () => {
    setIsLoading(true);
    const fetchData = idsCategories.length === 0 ? getAllSetDatos() : getCatalogoDatosByCategories(idsCategories.join(','));
  
    fetchData
      .then((response) => {
        const data = Array.isArray(response.data) ? response.data : [response.data];
        dispatch(setDataCatalogoFiltrado(data));
        setCantidadResultados(data.length);
        setIsLoading(false);
        setShowingResult(true);
        
        // Esperar 2 segundos antes de cerrar el modal
        setTimeout(() => {
          setShowingResult(false);
          handleOk();
        }, 1000);
      })
      .catch((error) => {
        console.error('Error al obtener los datos:', error);
        setIsLoading(false);
      });
  };
  
  return (
    <div>
      
      <Modal
        title={
          <div style={{ marginBottom: 13 }}>
            Filtros <img src={imgFiltro} alt='filtro' style={{ width: 13, height: 13, position: 'relative', top: 2, color: 'white' }}/>
          </div>
        }
        className='modal-filtro'
        centered
        open={isModalOpen}
        onOk={handleOk}
        resetClickedButtonsCount={resetClickedButtonsCount}
        closable={!isLoading && !showingResult}
        maskClosable={!isLoading && !showingResult}
        onCancel={isLoading || showingResult ? undefined : handleCancel}
        footer={[
          <div key="footer-buttons" style={{ display: 'flex', justifyContent: 'space-between', marginTop: 13 }}>
            <Button 
              onClick={resetButtons} 
              type='link' 
              style={{ color: 'rgb(255,255,255)'}} 
              disabled={isLoading || showingResult}
            >
              Quitar todos
            </Button>
            <Button 
              shape='round' 
              onClick={handleResultados} 
              style={{ backgroundColor: 'rgba(24, 144, 255, 1)', color: 'white', borderColor: 'rgba(24, 144, 255, 1)' }}
              disabled={isLoading || showingResult}
            >
              {isLoading ? 'Cargando...' : `Mostrar resultados`}
            </Button>
          </div>
        ]}
        >
          <p style={{ fontSize: 15, fontWeight: 'bold', position: 'relative', bottom: -16 }}>Categoría de datos</p>
          <p style={{ fontSize: 12 }}>Selecciona una opción para filtrar los resultados.</p>
          {/*<Divider orientation='horizontal' style={{ backgroundColor: '#fff' }}/>*/}
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={12}>
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: 15 }}>
                <Button
                  type='primary'
                  shape='round'
                  style={{
                    ...buttonStyle,
                    backgroundColor: clickedButtons.includes(1) ? 'rgba(24, 144, 255, 1)' : buttonStyle.backgroundColor,
                    borderColor: clickedButtons.includes(1) ? 'rgba(24, 144, 255, 1)' : buttonStyle.borderColor,
                    color: clickedButtons.includes(1) ? '#fff' : buttonStyle.color,
                    width: '100%',
                  }}
                  onClick={() => handleButtonClick(1)}
                >
                  Renta Variable
                  {clickedButtons.includes(1) && <CloseCircleFilled style={{ color: 'rgba(17, 114, 204, 1)'}}/>}
                </Button>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: 15 }}>
                <Button 
                  type='primary' 
                  shape='round' 
                  style={{
                    ...buttonStyle,
                    backgroundColor: clickedButtons.includes(2) ? 'rgba(24, 144, 255, 1)' : buttonStyle.backgroundColor,
                    borderColor: clickedButtons.includes(2) ? 'rgba(24, 144, 255, 1)' : buttonStyle.borderColor,
                    color: clickedButtons.includes(2) ? '#fff' : buttonStyle.color,
                    width: '100%',
                  }}
                  onClick={() => handleButtonClick(2)}>
                  Renta Fija
                  {clickedButtons.includes(2) && <CloseCircleFilled style={{ color: 'rgba(17, 114, 204, 1)'}}/>}
                </Button>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: 15 }}>
                <Button 
                  type='primary' 
                  shape='round' 
                  style={{
                    ...buttonStyle,
                    backgroundColor: clickedButtons.includes(3) ? 'rgba(24, 144, 255, 1)' : buttonStyle.backgroundColor,
                    borderColor: clickedButtons.includes(3) ? 'rgba(24, 144, 255, 1)' : buttonStyle.borderColor,
                    color: clickedButtons.includes(3) ? '#fff' : buttonStyle.color,
                    width: '100%',
                  }}
                  onClick={() => handleButtonClick(3)}>
                  Intermediación Financiera
                  {clickedButtons.includes(3) && <CloseCircleFilled style={{ color: 'rgba(17, 114, 204, 1)'}}/>}
                </Button>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: 15 }}>
                <Button 
                  type='primary' 
                  shape='round' 
                  style={{
                    ...buttonStyle,
                    backgroundColor: clickedButtons.includes(4) ? 'rgba(24, 144, 255, 1)' : buttonStyle.backgroundColor,
                    borderColor: clickedButtons.includes(4) ? 'rgba(24, 144, 255, 1)' : buttonStyle.borderColor,
                    color: clickedButtons.includes(4) ? '#fff' : buttonStyle.color,
                    width: '100%',
                  }}
                  onClick={() => handleButtonClick(4)}>
                  Compensación y Liquidación
                  {clickedButtons.includes(4) && <CloseCircleFilled style={{ color: 'rgba(17, 114, 204, 1)'}}/>}
                </Button>
              </div>
            </Col>
          </Row>
        </Modal>
    </div>
  )
}

export default ModalFiltro