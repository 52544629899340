import { Avatar, Button, Card, Carousel, Col, Row, Typography } from 'antd'
import React from 'react'
import { saveAs } from 'file-saver'


import partner from '../../assets/images/partnership.gif';
import datos from '../../assets/images/datos.gif';
import espacio from '../../assets/images/workspace.gif';
import consulta from '../../assets/images/cuaderno.gif';
import { Link } from 'react-router-dom';
import { getDocumento } from '../../services/Catalogo';
import { TipoDocumentoId } from '../../enum/enum';
 
const { Title, Text } = Typography;
const SliderBeneficiosMobile = () => {
    const idProductoInnovationLab = 30
    const onChange = (currentSlide) => {
        console.log(currentSlide);
    };

    const onRedirect = () => {
        window.scrollTo(0, 0)
    }

    const onDownloadDocumento = async (idProducto, idTipoDocumento) => {
        try {
          const docRes = await getDocumento(idProducto, idTipoDocumento)
          if(docRes) {
            const blobFile = new Blob(
              [docRes.buffer],
              { type: docRes.contentType }
            )
            saveAs(blobFile, docRes.fileName)
          }else {
            console.log('Sin respuesta');
          }
        } catch (error) {
          console.error('Error al descargar el documento');
        }
    }

  return (
    <Carousel afterChange={onChange} style={{ marginTop: 30 }}>
            <div>
                <Card className='card-detail-prod' bordered={false} style={{ height: 400, borderRadius: 20, marginLeft: 20, marginRight: 20, background: 'rgba(255, 255, 255, 0.06)', backdropFilter: 'blur(10px)' }}>
                    <Row justify="space-between" >
                        <Col span={24}>
                            <Avatar shape='circle' size={72} style={{ backgroundColor: '#053743' }}><img src={datos} alt='cohete' style={{ width: 45, height: 45 }}/></Avatar>
                            <Title level={4} style={{ color: 'white' }}> Conjuntos de datos </Title>
                            <Text style={{ color: 'white', fontSize: 15 }}>
                            Explora nuestro <Link style={{ color: '#06EFFE', fontWeight: 'bold', textDecoration: 'underline' }} to="/catalogo-datos" onClick={onRedirect}>Catálogo de datos</Link> para ver los sets de información de la Bolsa de Santiago que están disponibles para su uso y análisis en los Workspaces.
                            </Text>
                        </Col>
                    </Row>
                </Card>
            </div>
            <div>
                <Card className='card-detail-prod' bordered={false} style={{ height: 400, borderRadius: 20, marginLeft: 20, marginRight: 20, background: 'rgba(255, 255, 255, 0.06)', backdropFilter: 'blur(10px)' }}>
                    <Row justify="space-between" >
                        <Col span={24}>
                            <Row gutter={[16, 16]}>
                                <Col xs={24}>
                                    <Avatar shape='circle' size={72} style={{ backgroundColor: '#053743' }} ><img src={espacio} alt='cohete' style={{ width: 45, height: 45 }}/></Avatar>
                                    <Title level={4} style={{ color: 'white' }}> Workspaces </Title>
                                    <Text style={{ color: 'white', fontSize: 15 }}>
                                    Revisa los ambientes y planes disponibles en el siguiente Folleto, y la Guía de uso para entender cómo funciona este servicio. Solicita la habilitación de un Workspace a través del formulario de <Link style={{ color: '#06EFFE', fontWeight: 'bold', textDecoration: 'underline' }} to="/contacto" onClick={onRedirect}>Contacto.</Link>
                                    </Text>
                                </Col>
                                <Col xs={24}>
                                    <Button type='primary' className='btnfolleto' shape='round' onClick={() => onDownloadDocumento(idProductoInnovationLab, TipoDocumentoId.FOLLETO_PRODUCTO)} style={{ marginRight: 16,  backgroundColor: '#D9D9D9', color: 'black', fontWeight: 'bold', fontFamily: 'Roboto', fontSize: 13, width: '30%' }}>Folleto</Button>
                                    <Button type='primary' shape='round' onClick={() => onDownloadDocumento(idProductoInnovationLab, TipoDocumentoId.DOCUMENTACION)} style={{ backgroundColor: '#000', color: '#fff', fontWeight: 'bold', fontFamily: 'Roboto', fontSize: 13, borderColor: 'white', width: '40%' }}>Guía de uso</Button>
                                </Col>
                            </Row>
                            
                        </Col>
                    </Row>
                </Card>
            </div>
            <div>
                <Card className='card-detail-prod' bordered={false} style={{ height: 400, borderRadius: 20, marginLeft: 20, marginRight: 20, background: 'rgba(255, 255, 255, 0.06)', backdropFilter: 'blur(10px)' }}>
                    <Row justify="space-between" >
                        <Col span={24}>
                            <Avatar shape='circle' size={72} style={{ backgroundColor: '#053743' }}><img src={partner} alt='cohete' style={{ width: 45, height: 45 }}/></Avatar>
                            <Title level={4} style={{ color: 'white' }}> Partnership </Title>
                            <Text style={{ color: 'white', fontSize: 15 }}>
                            Si ya tienes una idea o proyecto en mente, pero no sabes por donde comenzar, conversémos para explorar oportunidades de financiamiento a través de un partnership. Y así co-crear en conjunto soluciones que entreguen valor al mercado y generen posibles ingresos.
                            </Text><br/>
                            <Text style={{ color: 'white', fontSize: 15 }}>
                            Para más información dirígite a la sección <Link style={{ color: '#06EFFE', fontWeight: 'bold', textDecoration: 'underline' }} to="/hazte-partner" onClick={onRedirect}>Hazte Partner</Link> de este sitio.
                            </Text>
                        </Col>
                    </Row>
                </Card>
            </div>
            <div>
                <Card className='card-detail-prod' bordered={false} style={{ height: 400, borderRadius: 20, marginLeft: 20, marginRight: 20, background: 'rgba(255, 255, 255, 0.06)', backdropFilter: 'blur(10px)' }}>
                    <Row justify="space-between" >
                        <Col span={24}>
                            <Avatar shape='circle' size={72} style={{ backgroundColor: '#053743' }}><img src={consulta} alt='consulta' style={{ width: 45, height: 45 }}/></Avatar>
                            <Title level={4} style={{ color: 'white' }}>  Consultas </Title>
                            <Text style={{ color: 'white', fontSize: 15 }}>
                            Si tienes consultas, necesitas mayor información del producto o deseas una demo, contáctate con nuestro equipo a través del formulario de la sección <Link style={{ color: '#06EFFE', fontWeight: 'bold', textDecoration: 'underline' }} to="/contacto" onClick={onRedirect}>Contacto</Link> de este sitio.
                            </Text>
                        </Col>
                    </Row>
                </Card>
            </div>
    </Carousel>
  )
}

export default SliderBeneficiosMobile