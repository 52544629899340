import { Card, Typography, Button, Row, Col } from 'antd';
import React from 'react';

import mano from '../../assets/images/hpartner.png';
import manosvg from '../../assets/images/hpartner.svg';
import manowebp from '../../assets/images/hpartner.webp';
import hp from '../../assets/images/hp.webp';
import hpmobile from '../../assets/images/hpmobile.png'


import { useNavigate } from 'react-router-dom';

const { Title, Text } = Typography;

const ContentHaztePartner = () => {

    const navigate = useNavigate()

    const onRedirect = (link) => {
        navigate(link)
        window.scrollTo(0, 0)
      }

    return (
        <div className='content-max'>
            {/*<div className='content-partner'>
                <img src={manowebp} alt='Hazte Partner' className='partner-image' />
                
            </div>*/}
            <Row>
                <Col xs={0} sm={0} md={24} lg={24} xl={24} xxl={24} className='content-partner'>
                    <img src={hp} alt='hazte partener' className='partner-image'/>
                    {/*<img src={manosvg} alt='hazte partener' className='partner-image'/>*/}
                    <Card className='partner-card' style={{ backgroundColor: '' }}>
                        <Title className='titulo' style={{ color: '#FFF', textAlign: 'center' }}>Hazte Partner</Title>
                        <Text className='descripcion-texto-hpartner' style={{ color: '#FFF' }}>Comparte tus conocimientos y modelos analíticos al mercado de una manera confiable y rentable a través de la innovación colaborativa, utilizando como base datos bursátiles y financieros entregados por la principal plaza bursátil de Chile.</Text>
                        <div className='btnHaztePartner'>
                            <Button type='primary' onClick={() => onRedirect('/contacto')} style={{ backgroundColor: '#fff', color: '#1890FF', fontWeight: 700 }} shape='round' >Hazte Partner</Button>
                        </div>
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0} className='content-partner-mobile'>
                    <img src={hpmobile} alt='hazte partener' className='partner-image'/>
                    <Card className='partner-card' style={{ backgroundColor: '' }}>
                        <Title className='titulo' style={{ color: '#FFF', textAlign: 'center' }}>Hazte Partner</Title>
                        <Text className='descripcion-texto-hpartner' style={{ color: '#FFF', textAlign: 'center' }}>Comparte tus conocimientos y modelos analíticos al mercado de una manera confiable y rentable a través de la innovación colaborativa, utilizando como base datos bursátiles y financieros entregados por la principal plaza bursátil de Chile.</Text>
                        <div className='btnHaztePartner'>
                            <Button type='primary' onClick={() => onRedirect('/contacto')} style={{ backgroundColor: '#fff', color: '#1890FF', fontWeight: 700 }} shape='round' >Hazte Partner</Button>
                        </div>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default ContentHaztePartner;
