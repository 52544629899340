import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { Col, Drawer, Layout, Menu, Row, Typography, Avatar } from 'antd'
import logo from '../../assets/images/LOGO INNOVATION LAB_ horizontal blanco.svg';
import {  MenuOutlined, RightOutlined } from '@ant-design/icons';

const { Header } = Layout;
const { Text } = Typography;
const HeaderContent = () => {

  const location = useLocation();

  const [drawerVisible, setDrawerVisible] = useState(false);

  const items = [
    { label: <Link style={{ fontWeight: 'bold' }} to="/home">Inicio</Link>, id: 1, pathnames: ['/home', '/'], },
    { label: <Link style={{ fontWeight: 'bold' }} to="/catalogo-datos">Catálogo de datos</Link>, id: 2,  pathnames: ['/catalogo-datos', '/detalle-producto'], },
    { label: <Link style={{ fontWeight: 'bold' }} to="/hazte-partner">Hazte Partner</Link>, id: 4, pathnames: ['/hazte-partner' ] },
    { label: <Link style={{ fontWeight: 'bold' }} to="/contacto">Contacto</Link>, id: 5, pathnames: ['/contacto']  },
  
  ]; 


  const onCloseDrawer = () => {
    setDrawerVisible(false);
  };

  const showDrawer = () => {
    setDrawerVisible(true);
  };

 

  return (
    <Header style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingTop: 7 }}>
    <div className='logo-menu-container'>
      <div className='logo'>
        <Link to='/home'><img src={logo} alt='logo' width={200}/></Link>
      </div>
        <Row>
          <Col xs={0} sm={0} md={24} lg={24} xl={24} xxl={24}>
            <Menu 
            mode="horizontal" 
            theme='dark'
            className='menu'
            >
              {items.map((item) => (
                <Menu.Item
                  key={item.id}
                  className={`menu-item ${item.pathnames.indexOf(location.pathname) !== -1 && item.pathnames.indexOf(location.pathname) !== 5 ? 'ant-menu-item-active' : ''}`}
                >
                  { item.label }
                </Menu.Item>
              ))}
            </Menu>
          </Col>
          <Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
          <div className="menu-icon-circle" onClick={showDrawer}>
            <Avatar size={40} shape='circle' style={{ backgroundColor: '#02111A ', borderColor: 'white' }}><MenuOutlined className="menu-icon" /></Avatar>
          </div>
          <Drawer
            title={
              <>
                {drawerVisible ? (<MenuOutlined style={{ fontSize: 25, color: 'white', marginLeft: -40 }}/> ) : ('')}
                    <span style={{ fontSize: 30, color: 'white', marginLeft: 15 }}>Menú</span>
                  {drawerVisible ? (<RightOutlined style={{ marginLeft: 56, fontSize: 25, color: 'white' }} onClick={onCloseDrawer}/>) : ('')}  
              </>
            }
            placement="right"
            closable={true}
            onClose={onCloseDrawer}
            open={drawerVisible}
            width={250}
            className='menudrawer'
          >
            <Menu theme="dark" mode="vertical" className="menu">
              {items.map((item, index) => (
                <Menu.Item key={item.id} onClick={onCloseDrawer} className={`menu-item ${item.pathnames.indexOf(location.pathname) !== -1 && item.pathnames.indexOf(location.pathname) !== 5 ? 'ant-menu-item-active' : ''}`}>
                  {item.label}
                </Menu.Item>
              ))}
            </Menu>
          </Drawer>
        </Col>
        </Row>
        
    </div>
    </Header>
  )
}

export default HeaderContent