import { Col, Row, Typography } from 'antd'
import React from 'react'
import ReactPlayer from 'react-player';

import bolsa from '../../assets/images/Bolsa.jpg'


const { Title, Text } = Typography;
const VideoInicio = () => {
  return (
    <div className='maxwidth-video'>
      <div className='video' style={{ }}>
          <Row gutter={0}>
              <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10}>
                      <Title level={4} style={{color: '#fff', fontWeight: 'bold', fontSize: 20 }}>¿Qué significa ser parte de Innovation Lab? </Title>
                      <Text style={{color: '#fff' }}>Cuando te unes a la comunidad de Brain Data, te asocias con la fuente de datos más confiable en el mercado bursátil y financiero de Chile. Pasas a ser parte de un <span style={{ color: '#FFC000', fontWeight: 'bold' }}>ecosistema de innovación abierta</span> impulsado por la principal plaza bursátil del país, la <span style={{ color: '#FFC000', fontWeight: 'bold' }}>Bolsa de Santiago.</span>
                      <br/><br/>
                      A través de Innovation Lab, buscamos aprovechar la vasta experiencia que tenemos como gestores de infraestructura tecnológica financiera, para poner a disposición nuestras herramientas y datos con el fin de potenciar y transformar el conocimiento en valor, y así, <span style={{ color: '#FFC000', fontWeight: 'bold' }}>co-crear</span> el futuro de la industria 
                      financiera juntos.</Text>
              </Col>
              <Col lg={2} xl={2} xxl={2}></Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                  <div style={{ right: 30, marginTop: 20 }}>
                      {/*<ReactPlayer url='https://www.youtube.com/watch?v=_tA5cinv0U8&ab_channel=Derivando' className='video-mobile' />*/}
                      <img src={bolsa} alt='bolsa' className='img-video'/>
                  </div>
              </Col>
          </Row>
      </div>
    </div>
  )
}

export default VideoInicio