import { PublicAxios } from './Helpers/publicInterceptorAxios';

const urlApi = process.env.REACT_APP_BACKEND_URL;

/**
 * @typedef {object} ResultadoAccionDto
 * @property {string} mensaje 
 * @property {boolean} accionFueCorrecta
 */

export const getTipoCliente = async () => {
    return PublicAxios.get(`${urlApi}/api/Contacto/TipoCliente`)
}

export const getTipoContacto = async () => {
    return PublicAxios.get(`${urlApi}/api/Contacto/MotivoContacto`)
}

/**
 * 
 * @param {*} data - informacion formulario
 * @returns {Promise<ResultadoAccionDto>}
 */
export const enviarFormulario = async (data) => {
    console.log('Data: ', data);
    return PublicAxios.post(`${urlApi}/api/Contacto/Solicitud`, data).then((res) => { return res.data })
}
