import React from 'react'
import DetalleCatalogo from './DetalleCatalogo'

const Detalle = () => {
  return (
    <div className='detalle'>
      <div className='detalle-catalogo'>
        <DetalleCatalogo/>
      </div>
    </div>
  )
}

export default Detalle