import React from 'react'
import HeaderContent from '../components/layout/Header'
import FooterContent from '../components/layout/Footer'
import Contacto from '../components/contacto/Contacto'

const ContactoPage = () => {
  return (
    <>
      <HeaderContent/>
      <Contacto/>
      <FooterContent/>
    </>
  )
}

export default ContactoPage