import { Col, Row, Typography } from 'antd'
import React from 'react'
import ContenidoCatalogo from './ContenidoCatalogo'
import BuscadorCatalogo from './BuscadorCatalogo';
import CardsCatalogo from './CardsCatalogo';

const { Title, Text } = Typography;
const CatalogoComponent = () => {
  return (
    <div className='catalogo'>
      <div className='catalago-componente'>
        <ContenidoCatalogo/>
        <BuscadorCatalogo/>
        <CardsCatalogo/>
      </div>
    </div>
  )
}

export default CatalogoComponent