import { Badge, Button, Card, Col, Pagination, Row, Typography, Spin, Space } from 'antd'
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import ModalFiltro from './ModalFiltro';
import { Link, useNavigate } from 'react-router-dom';
import imgFiltro from '../../assets/images/filter.svg'
import { getAllSetDatos } from '../../services/Catalogo';
import { setDataCatalogo } from '../../redux/reducers/AppReducer';

const { Text, Title } = Typography;
const CardsCatalogo = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [clickedButtonsCount, setClickedButtonsCount] = useState(0);
    const [loading, setLoading] = useState(false)


    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    useEffect(() => {
        fetchData()
    }, [])

    const dataCatalogo = useSelector(state => state.aplicacion.dataCatalogo);
    const dataCatalogoFiltrado = useSelector(state => state.aplicacion.dataCatalogoFiltrado)
    //const datosMostrar = dataCatalogoFiltrado.length > 0 ? dataCatalogoFiltrado[0] : dataCatalogo;

    const datosMostrar = Array.isArray(dataCatalogoFiltrado) && dataCatalogoFiltrado.length > 0
    ? dataCatalogoFiltrado
    : dataCatalogo;

    const handleUpdateClickedButtonsCount = (count) => {
    setClickedButtonsCount(count);
    };

    const showModal = () => {
        setIsModalOpen(true)
    }
    const handleOk = () => {
        setIsModalOpen(false);
    };
    
    const handleCancel = () => {
        setIsModalOpen(false);
    };
     
    const fetchData = async () => {
        try {
          setLoading(true);
          const values = await Promise.all([getAllSetDatos()]);
          dispatch(setDataCatalogo(values[0]?.data));
        } catch (error) {
          console.error('Error al obtener los datos:', error);
        } finally {
          setLoading(false);
        }
    };
    

    const onRedirect = () => {
        window.scrollTo(0, 0)
    }

    const onChangePage = (page) => {
        setCurrentPage(page);
    };
    
    const onShowSizeChange = (current, size) => {
        setPageSize(size);
        setCurrentPage(1);
    };

    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
  
    const paginatedData = datosMostrar.slice(startIndex, endIndex);

    const resetClickedButtonsCount = () => {
        setClickedButtonsCount(0);
    };

  return (
    <div className='img-catalogo'>
        <div className='card-content'>
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24}>
                    <div style={{ margin: '0 30px', marginTop: 40 }}>
                        <Badge count={clickedButtonsCount}>
                            <Button className='btnFiltro' shape='round' type="primary" onClick={showModal} style={{  }}>
                                Filtrar
                                {/*<FilterOutlined />*/}
                                <img src={imgFiltro} alt='filtro' style={{ width: 18, height: 18, marginLeft: 15, position: 'relative', bottom: -4 }}/>
                            </Button>  
                        </Badge>
                        <Text style={{ color: '#fff', marginLeft: 25 }}>{datosMostrar.length > 0 &&
                            datosMostrar.length} resultados</Text>
                    </div>
                </Col>
            </Row>
            <Row gutter={[16, 16]} style={{ marginTop: 20 }}>
                {loading ? (
                <Spin size='large' style={{ color: '#FFC000', display: 'flex', alignItems: 'center', justifyContent: 'center',  height: '100%', width: '100%' }}></Spin>
                ) : (
                    <>
                <Col xs={0} sm={0} md={24}>
                    {
                        datosMostrar.length > 0 &&
                        datosMostrar.slice(startIndex, endIndex).map((item) => (
                            <div key={item.IdSetDatos} style={{ margin: '0 30px', marginTop: 8 }}>
                            <Card
                                style={{
                                width: '100%',
                                //backgroundColor: '#03222A',
                                padding: '10px'
                                }}
                                className='card-catalogo-mobile'
                            >
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ marginTop: -35 }}>
                                    <p style={{ fontWeight: 700, fontSize: 16 }}>
                                    {item.NombreSetDatos}
                                    </p>
                                    <p style={{ position: 'relative', top: -13 }}>
                                    {item.DescripcionSetDatos}
                                    </p>
                                </div>
                                <div>
                                    <Link type='primary' shape='round' to={`/detalle-producto/${item.IdSetDatos}`} onClick={onRedirect} className='btnVerMas'>Ver más</Link>
                                </div>
                                </div>
                            </Card>
                        </div>
                        )
                        )
                    }
                </Col>
                <Col xs={24} sm={24} md={0}>
                    {
                        datosMostrar.length > 0 &&
                        datosMostrar.slice(startIndex, endIndex).map((item) => (
                            <div style={{ marginTop: 15 }}>
                                <Card key={item.IdSetDatos}
                                className='card-detail-prod-inno' 
                                bordered={false} 
                                style={{ borderRadius: 20, marginLeft: 20, marginRight: 20, border: '1px solid rgba(255, 255, 255, 0.20)', background: 'rgba(255, 255, 255, 0.06)', backdropFilter: 'blur(10px)' }}>
                                    <div style={{ color: '#fff' }}>
                                        <p style={{ color: 'white', textAlign: 'left', fontWeight: 'bold', fontSize: 16 }}>{item.NombreSetDatos}</p>
                                        <Row>
                                            <Col xs={24} sm={24}>
                                                {item.DescripcionSetDatos}
                                            </Col>
                                        </Row>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                                        <Space direction='vertical'>
                                            <Link type='primary'  shape='round' to={`/detalle-producto/${item.IdSetDatos}`} onClick={onRedirect} className='btnVerMas'>Ver más</Link>
                                        </Space>
                                    </div>
                                </Card>
                            </div>
                        ))
                    }
                </Col>
                </>
            )}
            </Row>
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24}>
                    <div style={{ marginTop: 40, display: 'flex', justifyContent: 'center' }}>
                    <Pagination
                        className='paginacion'
                        showSizeChanger
                        onShowSizeChange={onShowSizeChange}
                        current={currentPage}
                        pageSize={pageSize}
                        total={datosMostrar.length}
                        onChange={onChangePage}
                    />
                    </div>
                </Col>
            </Row>
            <ModalFiltro isModalOpen={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} onUpdateClickedButtonsCount={handleUpdateClickedButtonsCount} resetClickedButtonsCount={resetClickedButtonsCount}/>
        </div>
    </div>
  )
}

export default CardsCatalogo