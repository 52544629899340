import { Col, Row, Typography } from 'antd'
import React from 'react'

const { Title, Text } = Typography; 
const ContenidoCatalogo = () => {


  return (
    <div className='texto-content'>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24}>
          <div style={{  textAlign: 'center', margin: '0 30px' }}>
            <Title level={2} className='title' style={{ fontFamily: 'Roboto !important', fontSize: '35px !important' }}>
              Catálogo de datos
            </Title>
            <Text className='subtitle' style={{ fontFamily: 'Roboto !important' }}>
              Explora nuestro catálogo de datos transaccionales de la Bolsa de Santiago para obtener más información sobre los sets de datos disponibles para su uso y análisis en los Workspaces.
            </Text>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default ContenidoCatalogo