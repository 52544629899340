import { SendOutlined } from '@ant-design/icons'
import { Button, Checkbox, Col, Form, Input, Row, Select } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { MotivoContactoInnovationEnum } from '../../enum/enum'
import { enviarFormulario, getTipoCliente, getTipoContacto } from '../../services/Contacto'
import { daFormatoRut, esRutValido, getDvRut, getRutSinDv } from '../../utils/Util'
import ModalMensaje from '../modal/ModalMensaje'
import ModalTerminosCondiciones from '../modal/ModalTerminosCondiciones'

const MOTIVOS_RUT_Y_EMPRESA_OBLIGATORIO = [
    MotivoContactoInnovationEnum.QUIERO_CONTRATAR_PLAN,
    MotivoContactoInnovationEnum.QUIERO_SER_PARTNER,
  ]
const FormularioContactoMobile = () => {

    const location = useLocation();

    const [form] = Form.useForm();
    const [expandir, setExpandir] = useState(false)
    const [tipoCliente, setTipoCliente,] = useState(null);
    const [tipoContacto, setTipoContacto] = useState(null);
    const [rutValue, setRutValue] = useState('');

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [dataModalMsg, setDataModalMsg] = useState(null)

    useEffect(() => {
        const fetchData = async () => {
          try {
            const tipoClienteData = await getTipoCliente();
            const tipoContactoData = await getTipoContacto();
            setTipoCliente(tipoClienteData?.data);
            setTipoContacto(tipoContactoData?.data);
          } catch (error) {
            console.error('Error al obtener los datos:', error);
          }
        };
      
        fetchData();
      }, []);
    
      useEffect(() => {
        if (location.state && location.state.seleccionarHaztePartner && tipoContacto !== null) {
          const haztePartnerId = tipoContacto.find((opcion) => opcion.label === 'Quiero ser partner')?.value;
          form.setFieldsValue({ idMotivoContacto: haztePartnerId });
        }
      }, [location.state, form, tipoContacto]);
      
    
      const idMotivoContactoWatch = Form.useWatch("idMotivoContacto", form)
    
      const idTipoClienteWatch = Form.useWatch('idTipoCliente', form);
    
    
      const onFinish = async (value) => {
        try {
          
          const nombreCompleto = value.nombre + ' ' + value.apellidos;
          value.nombreCompleto = nombreCompleto;
          let dvrut = getDvRut(value.ruts);
          let rutsinformato = getRutSinDv(value.ruts);

          value.rut = rutsinformato + dvrut;
          const { nombre, apellidos, aceptaTerminos, comerciales, ruts, ...datosFinales } = value
    
          console.log('datosFinales: ', datosFinales);
          const result = await enviarFormulario(datosFinales)
          console.log('result: ', result)
          setDataModalMsg({
            message: result.accionFueCorrecta ? "Mensaje enviado correctamente, te contactaremos a la brevedad." : "Ocurrio un error al enviar su mensaje. Intente nuevamente",
            success: result.accionFueCorrecta
          })
          if (result.accionFueCorrecta) {
            form.resetFields();
          }
    
        } catch (error) {
          console.log(error)
          setDataModalMsg({
            message: "Ocurrio un error inesperado al enviar su mensaje. Intente nuevamente",
            success: false
          })
        }
      }
    
    
      const validateEmail = (rule, value) => {
        if (!value || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
          return Promise.resolve();
        }
        return Promise.reject('Ingresa un correo electrónico válido');
      };
    
      const onValidateRut = async (value) => {
        const esValido = esRutValido(value)
        if (
          idMotivoContactoWatch &&
          MOTIVOS_RUT_Y_EMPRESA_OBLIGATORIO.includes(idMotivoContactoWatch) &&
          !value
        )
        return Promise.reject(new Error('Ingresa un Rut válido'))
    
        if (esValido) {
            return Promise.resolve()
        }
        else if (!esValido) {
            return Promise.reject(new Error('Ingresa un Rut válido'))
        }
      }
      
      const onValidateNombreEmpresa = async (_, value, idTipoCliente) => {
        if (
          idMotivoContactoWatch &&
          MOTIVOS_RUT_Y_EMPRESA_OBLIGATORIO.includes(idMotivoContactoWatch) &&
          idTipoCliente !== 1 &&
          !value
        ) {
          return Promise.reject("Debe ingresar un nombre de empresa");
        }
        return Promise.resolve();
      };

      const onChange = (key) => {

      };
    
      const onChangeOpcional = (key) => {
    
      };

      const showModal = () => {
        setIsModalOpen(true)
      }
      const handleOk = () => {
          setIsModalOpen(false);
      };
    
      const handleCancel = () => {
          setIsModalOpen(false);
      };

  return (
    <>
        <Form layout='vertical' style={{ color: '#fff', marginTop: 30 }} onFinish={onFinish} form={form} >
              <Row gutter={0}>
                <Col className="gutter-row" xs={24} sm={24} md={12}>
                  <Form.Item name='nombre' label='Nombre' rules={[{ required: true, message: 'Ingrese un nombre' }]}>
                    <Input placeholder='Ingresa tu nombre' />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} sm={24} md={12}>
                  <Form.Item name='apellidos' label='Apellidos' rules={[{ required: true, message: 'Ingresa tus apellidos' }]}>
                    <Input placeholder='Ingresa tus apellidos' />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={0}>
                <Col className="gutter-row" xs={24} sm={24} md={12}>
                  <Form.Item name='email' label="Correo electrónico" 
                    rules={[
                      { required: true, message: 'Ingresa tu correo electrónico' },
                      { validator: validateEmail }
                    ]}>
                    <div className='top-mobile'>
                      <Input placeholder="Ingresa tu correo"/>
                    </div>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} sm={24} md={12}>
                  <Form.Item name='celular' label="Número de teléfono" rules={[{ required: true, message: 'Ingresa tu número de teléfono' }]}>
                    <div className='top-mobile'>
                      <Input placeholder="Ingresa tu número de teléfono" />
                    </div>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={0}>
                <Col className="gutter-row" xs={24} sm={24} md={12}>
                  <Form.Item name='idTipoCliente' label="Soy una" rules={[{ required: true, message: 'Selecciona una opción' }]}>
                    <div className='top-mobile'>
                    <Select
                        className='select-form'
                        placeholder="Selecciona un tipo de cliente"
                        options={tipoCliente}
                        value={form.getFieldValue('idTipoCliente')}
                        onChange={(value) => form.setFieldsValue({ idTipoCliente: value })}
                      />
                    </div>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} sm={24} md={12}>
                  <Form.Item
                    name='ruts'
                    label='Rut'
                    normalize={(value) => daFormatoRut(value)}
                    rules={[
                      {
                        required: true,
                        message: "Ingresa tu número de identificación",
                      },
                      {
                        validator: (_, value) => value ? onValidateRut(value) : Promise.resolve()
                      }
                    ]}
                  >
                    <Input placeholder='Ingresa tu Rut' />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={0}>
                <Col className="gutter-row" xs={24} sm={24} md={12}>
                  <Form.Item name='idMotivoContacto' label="Motivo" rules={[{ required: true, message: 'Selecciona una opción' }]}>
                    <Select
                      className='select-form'
                      placeholder="Selecciona un motivo"
                      options={tipoContacto}
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} sm={24} md={12}>
                  <Form.Item
                    name='empresa'
                    label="Nombre empresa"
                    rules={[
                      { validator: (_, value) => onValidateNombreEmpresa(_, value, idTipoClienteWatch) }
                    ]}>
                      <div className='top-mobile'>
                        <Input placeholder="Ingresar el nombre de la empresa" />
                      </div>
                  </Form.Item>
                </Col>
              </Row>
              <Row >
                <Col className="gutter-row" xs={24} sm={24} md={24}>
                  <Form.Item name='mensaje' label="Mensaje" rules={[{ required: true, message: 'Ingresa un mensaje' }]}>
                    <TextArea placeholder="Escribe tu mensaje aquí" rows={4} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                  <Col span={24}>
                      <Form.Item valuePropName="checked" name='aceptaTerminos' rules={[{ required: true, message: "", }, {
                            validator: (_, value) =>
                              value ? Promise.resolve() : Promise.reject("Debes aceptar términos y condiciones"),
                          },]}>
                        <Checkbox onChange={onChange} style={{ color: 'white', fontWeight: 'normal' }}>He leído y acepto los <Link type='link' onClick={showModal} style={{ color: '#06EFFE', fontWeight: 'bold' }}> Términos y condiciones y la Política de privacidad</Link></Checkbox>
                      </Form.Item>
                    </Col>
                    <Col span={24} style={{ marginTop: -10 }}>
                      <Form.Item valuePropName='checked' name='comerciales' rules={[{ required: false }]}>
                        <Checkbox onChange={onChangeOpcional} style={{ color: 'white', fontWeight: 'normal' }}>Acepto recibir comunicaciones comerciales electrónicas y/o newsletters (opcional)</Checkbox>
                      </Form.Item>
                    </Col>
                </Row>
                <Row justify='space-between'>
                  <Col xs={24} sm={24} md={7}>
                    <Button type='primary' shape='round' htmlType='submit'>
                      Enviar mensaje <SendOutlined />
                    </Button>
                  </Col>
                </Row>
        </Form>
        <ModalMensaje
        isModalOpen={dataModalMsg ? true : false}
        onClose={() => setDataModalMsg(null)}
        message={dataModalMsg?.message}
        success={dataModalMsg?.success}
      />
      <ModalTerminosCondiciones  isModalOpen={isModalOpen} handleOk={handleOk} handleCancel={handleCancel}/>
        </>
  )
}

export default FormularioContactoMobile