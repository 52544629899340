import { Card, Col, Row, Typography } from 'antd'

import uno from '../../assets/images/uno.svg';
import dos from '../../assets/images/dos.svg';
import tres from '../../assets/images/tres.svg';
import cuatro from '../../assets/images/cuatro.svg';


const { Title, Text } = Typography;
const CardsHaztePartner = () => {
  return (
    <div className='card-max'>
      <Row className='margen-mobile' gutter={0} style={{ marginTop: 30 }}>
        <Col xs={24} sm={24} md={24}>
          <div style={{  textAlign: 'center', marginLeft: 20, marginRight: 20 }}>
            <Title level={4} style={{ fontFamily: 'Roboto', color: '#fff', fontSize: 25 }}>Co-creando el futuro de la industria financiera juntos</Title>
            <Text style={{ fontFamily: 'Roboto', fontSize: 14, color: '#D9D9D6' }}>Innovation Lab te acompaña desde la exploración de datos hasta la distribución de tus descubrimientos por medio del desarrollo de productos financieros comercializables al mercado.</Text>
            <br/>
            <Text style={{ fontFamily: 'Roboto', fontSize: 14, color: '#D9D9D6' }}>Al ser un ecosistema de innovación abierta, nos enfocamos en propiciar espacios de trabajo colaborativos, seguros y eficientes. Revisa el camino de un partner de Innovation Lab.</Text>
          </div>
        </Col>
      </Row>
      <Row gutter={0}>
        <Col xs={24} sm={12} md={12} lg={6}>
          <div style={{ textAlign: 'center', margin: '40px 30px 10px 6px' }}>
            <Card className='card-height'>
              <div style={{ textAlign: 'left' }}>
                <img src={uno} alt='icono'/>
                <Title level={5} style={{ fontWeight: 900, color: 'white', marginTop: 2 }}>Plataforma de data science </Title>
                <Text style={{ color: 'white' }}>Contáctate con nosotros para revisar los Workspaces disponibles y las posibles oportunidades de co-creación. Nuestro equipo técnico configurará tu espacio de acuerdo a tus necesidades y objetivos. </Text>
                <br/>
                <br/>
                <Text style={{ color: 'white' }}>En la sección Catálogo de datos puedes obtener más información sobre los datos disponibles para ser explotados en los ambientes de trabajo.</Text>
              </div>
            </Card>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6}>
          <div style={{ textAlign: 'center', margin: '40px 30px 10px 6px' }}>
            <Card className='card-height'>
              <div style={{ textAlign: 'left' }}>
                <img src={dos} alt='icono'/>
                <Title level={5} style={{ fontWeight: 900, color: 'white', marginTop: 2 }}>Exploración </Title>
                <Text style={{ color: 'white' }}>Analiza y explota los datos utilizando las herramientas y capacidades que ofrecen los Workspaces de Innovation Lab altamente seguros y escalables. </Text>
                <br/>
                <br/>
                <Text style={{ color: 'white' }}>Todos tus descubrimientos están protegidos por marcos legales de seguridad de la información y licenciamiento, lo cual te entrega el control y propiedad intelectual de tus modelos y algoritmos.</Text>
              </div>
            </Card>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6}>
          <div style={{ textAlign: 'center', margin: '40px 30px 10px 6px' }}>
            <Card className='card-height'>
              <div style={{ textAlign: 'left' }}>
                <img src={tres} alt='icono'/>
                <Title level={5} style={{ fontWeight: 900, color: 'white', marginTop: 2 }}>Desarrollo de productos </Title>
                <Text style={{ color: 'white' }}>Junto a la colaboración del equipo Brain Data convertimos tus modelos y conocimientos en proyectos innovadores, desarrollando soluciones consumibles por tu organización o el mercado.</Text>
                <br/>
                <br/>
                <Text style={{ color: 'white' }}>
                Esta entrega puede ser por medio de productos como Dashboards, APIs, sets de datos, entre una variedad de opciones más.</Text>
              </div>
            </Card>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6}>
          <div style={{ textAlign: 'center', margin: '40px 30px 10px 6px' }}>
            <Card className='card-height'>
              <div style={{ textAlign: 'left' }}>
                <img src={cuatro} alt='icono'/>
                <Title level={5} style={{ fontWeight: 900, color: 'white', marginTop: 2 }}>Distribución </Title>
                <Text style={{ color: 'white' }}>Las soluciones creadas quedan listas para ser utilizadas en tu organización o comercializadas a través del ecosistema de productos de Brain Data generando nuevas oportunidades de ingresos. </Text>
                <br/>
                <br/>
                <Text style={{ color: 'white' }}>
                En este camino un equipo comercial te apoya con la definición de la estrategia de negocio y distribución de la solución al mercado, nos encargamos de gestiones comerciales como contratos, licencias, difusión, 
                facturación, entre otros.</Text>
              </div>
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default CardsHaztePartner