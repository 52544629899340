import React, { useState } from 'react'
import { Button, Col, Layout, Row, Space, Typography } from 'antd';
import CardsCaracteristicas from './CardsCaracteristicas';
import Beneficios from './Beneficios';
import VideoInicio from './VideoInicio';
import { useNavigate } from 'react-router-dom';


const { Content } = Layout;
const { Title, Text } = Typography;
const ContentInicio = () => {

  const [seleccionarHaztePartner, setSeleccionarHaztePartner] = useState(false)

  const navigate = useNavigate();

  const handleClick = () => {
    setSeleccionarHaztePartner(true)
    navigate('/contacto', { state: { seleccionarHaztePartner: true } })
    window.scrollTo(0, 0)
  }

  const onRedirect = (link) => {
    navigate(link)
    window.scrollTo(0, 0)
}

  return (
    <div className=''>
        <Content className='content-layout'>
          <div className='text-layout'>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <div className='textos-layotu'>
                  <Title style={{ color: 'white', fontWeight: 800, fontFamily: 'Roboto' }}>Impulsando la innovación a través del poder de los datos</Title>
                  <span style={{ color: 'white', fontSize: 24, fontWeight: 400, fontFamily: 'Roboto', textAlign: 'left' }}>Descubre, prueba y convierte tus ideas en productos con nuestros Workspaces sustentados en datos financieros de la Bolsa de Santiago</span>
                </div>
              </Col>
            </Row>
            <Row gutter={[0, 16]}>
              <Col xs={12} sm={12} md={24} lg={24} xl={24} xxl={24} style={{ marginTop: 50, marginLeft: -12 }} className='botones-inicio'>
                  <Button type='primary' onClick={() => onRedirect('/catalogo-datos')} shape='round' style={{ backgroundColor: 'transparent', borderColor: 'rgb(2555,255,255)', fontWeight: 500, marginLeft: 37, height: 40 }}>Catálogo de datos</Button>
                  <Button type='primary' onClick={handleClick} shape='round' className='btnsolicita' style={{ backgroundColor: 'rgba(255, 255, 255)', color: 'black', fontWeight: 500, marginLeft: 37, height: 40 }}>Solicita un Workspace</Button>
              </Col>
            </Row>
          </div>
        </Content>
        {/*<div className='content'>*/}
          <CardsCaracteristicas/>
          <div className='img-inicio'>
            <Beneficios/>
            <VideoInicio/>
          </div>
        {/*</div>*/}
    </div>
  )
}

export default ContentInicio