import { CloseSquareFilled } from '@ant-design/icons';
import { AutoComplete, Col, Row } from 'antd';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getAllSetDatos } from '../../services/Catalogo';
import { setDataCatalogoFiltrado } from '../../redux/reducers/AppReducer';

const BuscadorCatalogo = () => {
  const [data, setData] = useState([]);
  const [value, setValue] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    Promise.all([getAllSetDatos()])
      .then((response) => {
        const result = response[0]?.data || [];
        const suggestions = result.map((item) => ({
          value: item.NombreSetDatos,
          label: item.NombreSetDatos,
          data: item,
        }));
        setData(suggestions);
      });
  }, []);

  const onSelect = (selectedValue) => {
    setValue(selectedValue);
    const selectedOption = data.find((item) => item.value === selectedValue);
    if (selectedOption) {
      dispatch(setDataCatalogoFiltrado([selectedOption.data]));
    }
  };

  const onSearch = (searchText) => {
    setValue(searchText);
    const filteredOptions = data.filter((item) =>
      item.label.toLowerCase().includes(searchText.toLowerCase())
    );
    const filteredData = filteredOptions.map((item) => item.data);
    dispatch(setDataCatalogoFiltrado(filteredData));
  };

  return (
    <div className="buscador-content">
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24}>
          <AutoComplete
            style={{ width: '200px !important' }}
            className="buscador-catalogo"
            options={data}
            onSelect={onSelect}
            onSearch={onSearch}
            placeholder="Buscar por dato o palabras claves"
            allowClear={{ clearIcon: <CloseSquareFilled /> }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default BuscadorCatalogo;