import { Card, Col, Row, Space } from 'antd'
import React from 'react'
import world from '../../assets/images/wold.svg';
import light from '../../assets/images/luz.svg';
import data from '../../assets/images/data.svg';

const CardsCaracteristicas = () => {
  return (
    <div className='maxwidth-cards'>
        <div className='cards-medio-caracteristicas'>
            <Row gutter={[16, 16]} className='tarjetascaracteristicas'>
                    <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
                        <Card className='cards-caracteristicas'>
                            {/*<div className="list-item" style={{ display: 'flex', alignItems: 'center' }}>*/}
                                <img src={world} alt='check' style={{ marginRight: '10px' }} />
                                <div className="labels">
                                    <p style={{ fontWeight: 'bold', textAlign: 'left', fontSize: 18, marginTop: 2 }}>Desbloquea el verdadero valor de los datos </p>
                                </div>
                            {/*</div>*/}
                            <p style={{ fontSize: 15 }}>Con Innovation Lab tendrás acceso a conjuntos de datos transaccionales de la Bolsa de Santiago, poderosas herramientas de análisis y oportunidades comerciales, todo en un ecosistema seguro e innovador.</p>
                        </Card>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
                        <Card className='cards-caracteristicas'>
                            {/*<div className="list-item" style={{ display: 'flex', alignItems: 'center' }}>*/}
                                <img src={data} alt='check' style={{ marginRight: '10px' }} />
                                <div className="labels">
                                    <p style={{ fontWeight: 'bold', textAlign: 'left', fontSize: 18, marginTop: 2 }}>Plataforma de data science moderna y robusta </p>
                                </div>
                            {/*</div>*/}
                            <p style={{ fontSize: 15 }}>Ofrecemos ambientes de trabajo o Workspaces seguros donde podrás analizar datos de la Bolsa, y poner en práctica tus conocimientos y expertiz para crear modelos. Siempre tendrás a disposición un equipo de Brain Data que te apoyará en el proceso.</p>
                        </Card>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
                        <Card className='cards-caracteristicas'>
                            {/*<div className="list-item" style={{ display: 'flex', alignItems: 'center' }}>*/}
                                <img src={light} alt='check' style={{ marginRight: '10px' }} />
                                <div className="labels">
                                    <p style={{ fontWeight: 'bold', textAlign: 'left', fontSize: 18, marginTop: 2 }}>Comercializa tus ideas y conocimientos </p>
                                </div>
                            {/*</div>*/}
                            <p style={{ fontSize: 15 }}>Co-creamos oportunidades de ingresos a partir de la comercialización de productos basados en tus modelos desarrollados en los Workspaces, aprovechando la amplia distribución que tiene la Bolsa en los mercados bursátiles y financieros.</p>
                        </Card>
                    </Col>
            </Row>
        </div>
    </div>
  )
}

export default CardsCaracteristicas