import { LeftCircleFilled } from '@ant-design/icons';
import { Button, Col, Row, Typography } from 'antd'
import React from 'react'
import TabDescripcion from './TabDescripcion';
import TabEsquema from './TabEsquema';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const { Title, Text } = Typography;

const DetalleCatalogo = () => {

  const navigate = useNavigate()
  const dataCatalogoById = useSelector(state => state.aplicacion.dataCatalogoById);


  return (
    <div>
      <Row gutter={[16, 16]} style={{ marginTop: 40 }}>
        <Col xs={24} sm={24} md={24}>
          <div className='div-detalle' style={{ color: '#FFC000', paddingLeft: 10 }}>
            {/*<LeftCircleFilled /><Text style={{ color: '#FFC000', marginLeft: 10 }}>Volver al catálogo</Text>*/}
            <Button type='link' style={{ color: '#FFC000'}} icon={<LeftCircleFilled/>} onClick={() => navigate('/catalogo-datos')} >Volver al catálogo</Button>
          </div>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={22} sm={24} md={24} className='descripcion-detalle'>
          <Title level={4} style={{ color: '#fff', fontFamily: 'Roboto' }}>{dataCatalogoById[0]?.setDatos?.NombreSetDatos}</Title>
          <Text style={{ color: '#fff' }} className='texto-detalle'>{dataCatalogoById[0]?.setDatos?.DescripcionSetDatos}</Text>
        </Col>
      </Row>
      <div className='img-detalle'>
        <Row gutter={[16, 16]} style={{ marginTop: 40 }} className='tab-descripcion'>
          <Col xs={24} sm={24} md={24}>
            <TabDescripcion/>
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginTop: 40 }} className='tab-esquema '>
          <Col xs={24} sm={24} md={24}>
            <TabEsquema/>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default DetalleCatalogo