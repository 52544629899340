import React from 'react'
import HeaderContent from '../components/layout/Header'
import ContentInicio from '../components/inicio/ContentInicio'
import FooterContent from '../components/layout/Footer'

const PagePrincipal = () => {
  return (
    <>
      <HeaderContent/>
      <ContentInicio/>
      <FooterContent/>
    </>
  )
}

export default PagePrincipal