import { Card, Carousel, Col, Row, Typography } from 'antd';
import React, { useLayoutEffect, useRef, useState } from 'react';
import SliderBeneficiosWeb from './SliderBeneficiosWeb';
import SliderBeneficiosMobile from './SliderBeneficiosMobile';


const { Title, Text } = Typography;

const Beneficios = () => {

 

  

  return (
    <div className='maxwidth-beneficios'>
      <div style={{ marginTop: 50 }}>
          <Row gutter={0} className='margin-beneficio' style={{  }}>
              <Col xs={24} sm={24} md={20} lg={22} xl={22} xxl={22} className="detalle-beneficio" style={{ textAlign: 'center' }}>
                      <Title level={1} style={{ color: '#fff' }}>
                      ¿Qué es Innovation Lab?
                      </Title>
                      <Text style={{ color: '#fff', fontSize: 15 }}>
                      Innovation Lab es un producto que forma parte del ecosistema de soluciones digitales de Brain Data. Este ofrece ambientes de trabajo o <span style={{ color: '#FFC000', fontWeight: 'bold' }}>Workspaces</span> para investigadores y científicos de datos que deseen <span style={{ color: '#FFC000', fontWeight: 'bold' }}>testear y experimentar con data transaccional de la Bolsa de Santiago</span>, para así explorar en conjunto nuevas oportunidades de negocio.
                      <br/>
                      <br/>
                      Esta plataforma de data science reúne a una <span style={{ color: '#FFC000', fontWeight: 'bold' }}>comunidad</span> de proveedores de datos bursátiles con expertos en análisis, para entregar soluciones de valor a consumidores de información del mercado de capitales.
                      </Text>
              </Col>  
          </Row>
          <Row gutter={0}>
            <Col xs={0} sm={0} md={23} lg={24} xl={24} xxl={24}><SliderBeneficiosWeb/></Col>
            <Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}><SliderBeneficiosMobile/></Col>
          </Row>
      </div>
  </div>
  );
};

export default Beneficios;