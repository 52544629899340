import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Button, Space } from 'antd'
import React from 'react'

const Controles = ({ handleNext, handlePrev }) => {
  return (
    <div style={{ position: 'static' }}>
        <div className='posicionamiento' style={{  }}>
            <Space>
                <Button type='primary' shape='circle' style={{ backgroundColor: '#05313D', color: 'white' }} onClick={handlePrev}><LeftOutlined/></Button>
                <Button type='primary' shape='circle' style={{ backgroundColor: '#05313D', color: 'white' }} onClick={handleNext}><RightOutlined/></Button>
            </Space>
        </div>
  </div>
  )
}

export default Controles