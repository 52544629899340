import FormularioContacto from './FormularioContacto';
import ContentContacto from './ContentContacto';

const Contacto = () => {

return (
  <div className='conmtcrt'>
    <div className='contctanos'>
      <ContentContacto className='content-contacto'/>
      <FormularioContacto className='formulario-contacto'/>
    </div>
  </div>
);
}

export default Contacto