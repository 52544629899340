import React from 'react'
import HeaderContent from '../components/layout/Header'
import FooterContent from '../components/layout/Footer'
import CatalogoComponent from '../components/catalogo/Catalogo'

const CatalogoPage = () => {
  return (
    <>
        <HeaderContent/>
        <CatalogoComponent/>
        <FooterContent/>
    </>
  )
}

export default CatalogoPage